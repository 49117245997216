import { logArgs } from "@/helpers/app";
import { authSignInAnonymously, observeAuthState } from "@/lib/firebase/auth";
import { setLocalStorageItem } from "@/lib/local-storage";
import { User } from "firebase/auth";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface UserContextProps {
  user?: User | null;
  isLoggedIn: boolean;
}

const UserContext = createContext<UserContextProps>({} as UserContextProps);

interface Props {
  children: ReactNode;
}

export const UserProvider = (props: Props) => {
  const [user, setUser] = useState<User | null>();

  const isLoggedIn = typeof user?.uid === "string" && !user?.isAnonymous;

  useEffect(() => {
    logArgs("user: ", {
      id: user?.uid,
      email: user?.email,
      isAnonymous: user?.isAnonymous,
    });
  }, [user]);

  useEffect(() => {
    const unsub = observeAuthState((user) => {
      if (user?.isAnonymous) {
        setLocalStorageItem("auid", user.uid);
      }
      setUser(user);
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    if (user !== null) return;
    (async () => {
      const credentials = await authSignInAnonymously();
      setUser(credentials.user);
    })();
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        isLoggedIn,
      }}
      {...props}
    />
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within the UserProvider");
  }
  return context;
};
