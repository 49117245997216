import { Dialogs } from "@/components/dialogs";
import { Footer } from "@/components/footer";
import { Header } from "@/components/header";
import { Outlet } from "react-router-dom";

export default function AppLayout() {
  return (
    <main>
      <Header />
      <div className="pt-[70px] w-full min-h-screen">
        <Outlet />
      </div>
      <Footer />
      <Dialogs />
    </main>
  );
}
