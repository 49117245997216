import { Link } from "react-router-dom";
import { ThemeToggle } from "../theme-toggle";
import { AuthMenu } from "./auth-menu";
import logo from "/logo.svg";

export const Header = () => {
  return (
    <div className="fixed top-0 left-0 right-0 h-[70px] bg-background border-input border-b shadow-sm z-50">
      <div className="h-[70px] container flex justify-between items-center">
        <div className="flex items-center gap-4">
          <Link to="/">
            <img src={logo} alt="Itapulab" width={121} height={37} />
          </Link>
        </div>
        <div className="flex items-center">
          <ThemeToggle />
          <AuthMenu />
        </div>
      </div>
    </div>
  );
};
