import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  User,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInAnonymously,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { getPublicURL } from "@/helpers/app";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../local-storage";
import { auth } from "./app";

const facebookProvider = new FacebookAuthProvider();
const googleProvider = new GoogleAuthProvider();

const publicUrl = getPublicURL();

export async function authSignInAnonymously() {
  return await signInAnonymously(auth);
}

// observer
export function observeAuthState(resultHandler: (user: User | null) => void) {
  return onAuthStateChanged(auth, (user) => {
    if (user) resultHandler(user);
    else resultHandler(null);
  });
}

// sign in with link
export async function authSendSignInLinkToEmail(
  email: string,
  contractId?: string
) {
  await sendSignInLinkToEmail(auth, email, {
    url: `${publicUrl}/join${contractId ? "?id=" + contractId : ""}`,
    handleCodeInApp: true,
  });
  setLocalStorageItem("email-to-sign-in", email);
}

export async function authSignInWithEmailLink(confirmationEmail?: string) {
  if (!isSignInWithEmailLink(auth, window.location.href)) {
    throw new Error("Link inválido");
  }
  const email = getLocalStorageItem("email-to-sign-in") ?? confirmationEmail;
  if (!email) {
    throw new Error("Email não encontrado");
  }
  await signInWithEmailLink(auth, email, window.location.href);
  removeLocalStorageItem("email-to-sign-in");
}

// sign in with providers
export async function authGoogleSignIn() {
  await signInWithPopup(auth, googleProvider);
}

export async function authFacebookSignIn() {
  await signInWithPopup(auth, facebookProvider);
}

// sign out
export async function authSignOut(onToken?: boolean) {
  if (onToken) return;
  await signOut(auth);
}
