import { Suspense, lazy } from "react";
import { useSearchParams } from "react-router-dom";

const LoginDialog = lazy(() => import("@/components/login-dialog"));
const LogoutDialog = lazy(() => import("@/components/logout-dialog"));

export const Dialogs = () => {
  const [params] = useSearchParams();
  const isLoginOpen = params.get("login") === "open";
  const isLogoutOpen = params.get("logout") === "open";

  return (
    <Suspense fallback={null}>
      {isLoginOpen ? <LoginDialog /> : null}
      {isLogoutOpen ? <LogoutDialog /> : null}
    </Suspense>
  );
};
