import * as Sentry from "@sentry/react";
import { FirebaseError } from "../types/firebase";

const skippedExceptionsCode = [
  "ignored-error",
  "auth/user-not-found",
  // "auth/wrong-password",
  "auth/invalid-action-code",
  "auth/too-many-requests",
  // "auth/requires-recent-login",
  "auth/network-request-failed",
  // "auth/code-expired",
  // "auth/invalid-verification-code",
  // 'permission-denied',
  // 'functions/already-exists',
];

const skippedExceptionsMessage = [
  "Failed to get document because the client is offline.",
  // "Esgotou-se o tempo limite da solicitação.",
];

// const skippedExceptionsFnName = ['importMenu'];

const shouldCapture = (_fnName: string, error: FirebaseError) => {
  try {
    const { code, message } = error;
    // if (skippedExceptionsFnName.includes(fnName)) return false;
    if (code && skippedExceptionsCode.includes(code)) return false;
    if (message && skippedExceptionsMessage.includes(message)) return false;
    return true;
  } catch (error) {
    return true;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const captureException = (funcName: string, error: any) => {
  // These cases should not be monitored
  if ("code" in error && !shouldCapture(funcName, error)) {
    return;
  }
  // Send error to sentry
  return Sentry.captureException(error, { tags: { funcName } });
};
