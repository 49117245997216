import { getAppVersion } from "@/helpers/app";
import { Link, useParams } from "react-router-dom";

const version = getAppVersion();
const year = new Date().getFullYear();

export const Footer = () => {
  const params = useParams();
  const { id } = params;

  if (id) return null;
  return (
    <div className="w-full container p-4 flex flex-col gap-2 md:flex-row justify-between text-sm text-muted-foreground">
      <p>
        {year} | © Contratim v{version}
      </p>
      <div className="flex gap-4 underline">
        <Link to="/">Termos de uso</Link>
        <Link to="/">Política de privacidade</Link>
      </div>
    </div>
  );
};
