import { Toaster } from "@/components/ui/toaster";
import { TooltipProvider } from "@/components/ui/tooltip";
import { ReactNode } from "react";
import { ThemeProvider } from "./theme";
import { UserProvider } from "./user";
// import "@/lib/dayjs";

export function Providers({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider defaultTheme="system">
      <TooltipProvider>
        <UserProvider>{children}</UserProvider>
      </TooltipProvider>
      <Toaster />
    </ThemeProvider>
  );
}
