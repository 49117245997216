/* eslint-disable @typescript-eslint/no-explicit-any */
import { captureException } from "@/lib/sentry/utils";
import pack from "../../package.json";

export const getAppVersion = (): string | undefined => pack.version;

export const getPublicURL = () => {
  return import.meta.env.DEV
    ? "http://localhost:5173"
    : import.meta.env.VITE_PUBLIC_URL;
};

export const logArgs = (label: string, value?: any) => {
  if (process.env.NODE_ENV !== "development") return;
  if (!value) {
    console.log(label);
  } else {
    console.log(label, value);
  }
};

export const logError = (funcName: string, error: any) => {
  if (process.env.NODE_ENV === "production") {
    captureException(funcName, error);
  }
  return console.log(`%c${funcName} error: `, "color: red", error);
};

export const delay = async (ms: number): Promise<void> =>
  new Promise((res) => setTimeout(() => res(), ms));

export const assertPreconditions = (precondition: any[]) => {
  if (precondition.findIndex((item) => !item) !== -1) {
    throw new Error("Parâmetros inválidos");
  }
};
