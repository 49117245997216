/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { Button } from "./components/ui/button";
import { logError } from "./helpers/app";

export default function ErrorPage() {
  const error = useRouteError();

  const reload = () => {
    if (window) {
      window.location.reload();
    }
  };

  useEffect(() => {
    logError("routerError: ", error);
  }, [error]);

  return (
    <div
      id="error-page"
      className="w-screen min-h-screen flex justify-center items-center"
    >
      <div className="max-w-xl flex flex-col gap-6 items-center">
        <h1 className="text-2xl">Oops!</h1>
        <p>Algo inesperado aconteceu =/</p>
        <code className="p-4 bg-background-secondary rounded-lg">
          <p>
            {/* @ts-ignore */}
            <i>{error.statusText || error.message}</i>
          </p>
        </code>
        <Button onClick={reload}>Tentar novamente</Button>
      </div>
    </div>
  );
}
