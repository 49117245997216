import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastVariants,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { AlertOctagon, CheckCircle2, XCircle } from "lucide-react";

const Icon = ({ variant }: ToastVariants) => {
  if (variant === "warning") {
    return <AlertOctagon className="w-5 h-5 text-warning-foreground" />;
  } else if (variant === "destructive") {
    return <XCircle className="w-5 h-5 text-destructive" />;
  }
  return <CheckCircle2 className="w-5 h-5 text-success-foreground" />;
};

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex items-center gap-4">
              <Icon variant={props.variant} />
              <div className="space-y-1 pr-6">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription>{description}</ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
