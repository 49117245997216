import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useUserContext } from "@/context/user";
import { LogIn, LogOut, Menu, UserRound } from "lucide-react";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "../ui/button";

export const AuthMenu = () => {
  const { user, isLoggedIn } = useUserContext();
  const [params] = useSearchParams();
  const step = params.get("step");
  const linkPrefix = step ? `?step=${step}&` : "?";
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="lg" variant="outline" className="ml-2 px-2 rounded-full">
          <div className="w-8 h-8 bg-muted rounded-full flex justify-center items-center overflow-hidden">
            {user?.photoURL ? (
              <img src={user.photoURL} alt="Imagem do usuário" />
            ) : (
              <UserRound size="16" />
            )}
          </div>
          <Menu size="16" />
          <span className="sr-only">User menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="p-2 md:min-w-[200px]">
        <Link to="meus-contratos">
          <DropdownMenuItem>Meus contratos</DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator />
        {isLoggedIn ? (
          <Link to={linkPrefix + "logout=open"}>
            <DropdownMenuItem className="gap-2">
              Sair <LogOut size="14" />
            </DropdownMenuItem>
          </Link>
        ) : (
          <>
            <Link to={linkPrefix + "login=open"}>
              <DropdownMenuItem className="gap-2">
                Entrar <LogIn size="14" />
              </DropdownMenuItem>
            </Link>
            <Link to={linkPrefix + "logout=open"}>
              <DropdownMenuItem className="gap-2 text-destructive">
                Encerrar sessão <LogOut size="14" />
              </DropdownMenuItem>
            </Link>
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
