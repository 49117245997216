import Loading from "@/components/loading";
import { Providers } from "@/context/providers";
import ErrorPage from "@/error-page";
import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./app";

import * as Sentry from "@sentry/react";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <Providers>
          <App />
        </Providers>
      </Suspense>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        Component: lazy(() => import("./app._index")),
      },
      {
        path: "join",
        Component: lazy(() => import("./join")),
      },
      {
        path: "logout",
        Component: lazy(() => import("./logout")),
      },
      {
        path: "contratos",
        Component: lazy(() => import("./app.contracts")),
        children: [
          {
            path: ":slug/:id",
            Component: lazy(() => import("./app.contracts.$slug.$id")),
          },
          {
            path: ":slug/:id/revisao",
            Component: lazy(() => import("./app.contracts.$slug.$id.review")),
          },
          {
            path: ":slug/:id/pagamento",
            Component: lazy(() => import("./app.contracts.$slug.$id.payment")),
          },
          {
            path: ":slug/:id/confirmando",
            Component: lazy(
              () => import("./app.contracts.$slug.$id.confirming")
            ),
          },
          {
            path: ":slug/:id/download",
            Component: lazy(() => import("./app.contracts.$slug.$id.download")),
          },
        ],
      },
      {
        path: "meus-contratos",
        Component: lazy(() => import("./app.my-contracts")),
        children: [
          {
            path: "",
            Component: lazy(() => import("./app.my-contracts._index")),
          },
          {
            path: ":id",
            Component: lazy(() => import("./app.my-contracts.$id")),
            children: [
              {
                path: "",
                Component: lazy(() => import("./app.my-contracts.$id._index")),
              },
              {
                path: "visualizar",
                Component: lazy(() => import("./app.my-contracts.$id.view")),
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default function Root() {
  return <RouterProvider router={router} />;
}
