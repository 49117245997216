import { Loader2 } from "lucide-react";
import loader from "/loader.svg";

export default function Loading() {
  return (
    <div className="w-screen h-screen absolute top-0 left-0 flex justify-center items-center">
      <div className="flex flex-col items-center gap-2">
        <div className="relative">
          <img src={loader} alt="carregando..." className="w-[80px]" />
          <Loader2
            size="20"
            className="absolute top-[30px] left-[30.5px] animate-spin text-primary"
          />
        </div>
        <p className="text-sm text-muted-foreground">Carregando...</p>
      </div>
    </div>
  );
}
